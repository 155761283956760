import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import ReactTooltip from "react-tooltip"

const SizeModelSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 10px;
`

const SizeModelChooseWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .__react_component_tooltip {
    background: #fff;
    color: #262626;
    max-width: 505px;
    border-radius: 12px 1px;
    border-width: 1px;
    border-color: #5db9bc;
    padding: 10px;
  }

  .__react_component_tooltip h1 {
    margin: 0px 0px 10px 0px;
    font-family: "BebasNeueBold";
    font-size: 24px;
  }

  .__react_component_tooltip p {
    margin: 0px;
    font-family: "Gotham Book";
    font-size: 14px;
    line-height: 1.2;
  }

  .__react_component_tooltip.show {
    opacity: 1;
  }
  .__react_component_tooltip.place-left:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    right: -6px;
    top: 50%;
    margin-top: -4px;
    border-left-color: #5db9bc;
    border-left-style: solid;
    border-left-width: 6px;
  }
  .__react_component_tooltip.place-right:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    left: -6px;
    top: 50%;
    margin-top: -4px;
    border-right-color: #5db9bc;
    border-right-style: solid;
    border-right-width: 6px;
  }
  .__react_component_tooltip.place-top:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
    border-top-color: #5db9bc;
    border-top-style: solid;
    border-top-width: 6px;
  }
  .__react_component_tooltip.place-bottom:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: -6px;
    left: 50%;
    margin-left: -8px;
    border-bottom-color: #5db9bc;
    border-bottom-style: solid;
    border-bottom-width: 6px;
  }
`

const SizeModelChooseText = styled.p`
  font-family: "Museo";
  font-weight: bold;
  font-size: 16px;
  line-height: 1.2;
  margin: 0px;

  span {
    text-transform: lowercase;
    padding: 0px 0px;
    height: 15px;
    width: 15px;
    font-size: 11px;
    font-weight: bold;
    border-radius: 50%;
    border: 2px solid #47bebd;
    color: #47bebd;
    display: inline-flex;
    align-items: center;
    position: relative;
    justify-content: center;
    cursor: pointer;
    top: -2px;
  }
`

const SizeModelSectionBox = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0px;
`

const SizeModelBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 170px;
  padding: 3px;
  cursor: pointer;
  border: ${({ active }) =>
    active ? "2px solid #5db9bc" : "0px solid #5db9bc"};
  border-radius: ${({ active }) => (active ? "12px 1px" : "0px")};
  margin: 0px 5px 0px 0px;

  :hover {
    border: 2px solid #5db9bc;
    border-radius: 12px 1px;
  }

  @media (max-width: 600px) {
    max-width: calc(50% - 6px);
  }
`

const SizeModelBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ active }) => (active ? "#D9F0F1" : "inherit")};
  border: ${({ active }) =>
    active ? "0.5px solid #fff" : "0.5px solid #262626"};
  border-radius: 12px 1px;
  font-weight: ${({ active }) => (active ? "bold" : "500")};
  overflow: hidden;
`

const SizeModelTextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 9px 9px 5px;

  @media (max-width: 360px) {
    padding: 9px 6px 5px;
  }
`

const SizeModelText = styled.p`
  font-family: "Gotham Book";
  font-size: 14px;
  line-height: 1.2;
  margin: 0px;

  @media (max-width: 360px) {
    font-size: 13.5px;
  }
`

const SizeModelSection = ({
  intl,
  data,
  handleChange,
  chooseValue,
  section,
}) => {
  return (
    data && (
      <SizeModelSectionWrapper>
        {data.title && (
          <SizeModelChooseWrapper>
            <SizeModelChooseText
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
            {section && (
              <ReactTooltip id="size_tooltip" html={true} place="right" />
            )}
          </SizeModelChooseWrapper>
        )}
        <SizeModelSectionBox>
          {data.list &&
            data.list.map((item, index) => (
              <SizeModelBox
                key={index.toString()}
                onClick={() => handleChange(item.type)}
                active={chooseValue === item.type}
              >
                <SizeModelBoxWrapper active={chooseValue === item.type}>
                  <SizeModelTextBox>
                    <SizeModelText
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    />
                  </SizeModelTextBox>
                </SizeModelBoxWrapper>
              </SizeModelBox>
            ))}
        </SizeModelSectionBox>
      </SizeModelSectionWrapper>
    )
  )
}

export default injectIntl(SizeModelSection)
